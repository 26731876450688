<template>
  <div class="team">
    <v-card id="printable" class="white pa-3">
      <h1 class="text-center subtitle-4 black--text"> الملف الشخصي </h1>
      <h2 class="text-center subtitle-5 black--text"> ({{ $route.params.name }}) </h2>
      <v-row v-if="isPageNotLoading" class="mt-5">
        <v-col md="8" sm="12" cols="12">
          <img v-if="teacherData.account_img" :src="content_url + teacherData.account_img" alt="" width="200"
            height="200" />
          <img v-else src="../../../../assets/img/default_profile.png" alt="" width="200" height="200" />
          <div>
            <v-btn color="primary" class="mt-2" @click="goToAbsence"> عرض الغيابات والحضور </v-btn>
          </div>
          <div>
            <v-btn color="primary" class="mt-2" @click="goToNotificatoin"> عرض الاشعارات </v-btn>
          </div>
          <div>
            <v-btn color="primary" class="mt-2" @click="goToExams"> عرض الامتحانات والدرجات </v-btn>
          </div>
        </v-col>
        <v-col md="4" sm="12" cols="12">
          <!-- <div class="mb-2 d-flex justify-center">
            <v-btn
              color="warning"
              @click="printDiv"
            >
              طباعة
            </v-btn>
          </div> -->
          <!-- name -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">الاسم </span> {{ teacherData.account_name }}
          </div>
          <!-- mobile -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">الهاتف </span> {{ teacherData.account_mobile }}
          </div>
          <!-- card_number -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">رقم البصمة</span> {{ teacherData.account_card_number }}
          </div>
          <!-- email -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">الايميل </span> {{ teacherData.account_email }}
          </div>
          <!-- password -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">الرمز </span> {{ teacherData.account_password_show }}
          </div>
          <!-- address -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">العنوان </span> {{ teacherData.account_address }}
          </div>
          <!-- notification -->
          <div v-if="teacherData.isAccountCanSendNotification === true" class="py-2 px-2 d-flex justify-space-between"
            style="border: 1px solid cyan">
            <span class="primary--text">ارسال رسائل او تقارير </span> فعال
          </div>
          <div v-else class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">ارسال رسائل او تقارير </span> متوقف
          </div>
          <!-- accountDisable -->
          <div v-if="teacherData.isAccountDisabled === false" class="py-2 px-2 d-flex justify-space-between"
            style="border: 1px solid cyan">
            <span class="primary--text">الحساب</span> فعال
          </div>
          <div v-else class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">الحساب</span> متوقف
          </div>
          <!-- accountBirthday -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">تاريخ الميلاد</span> {{ teacherData.account_birthday }}
          </div>
          <!-- classSchool -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">الصف والشعبة</span> {{ teacherData.account_division_current.class_name }}--{{
            teacherData.account_division_current.leader }}
          </div>
          <!-- student_certificate_national_id -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">البطاقة الوطنية</span>
            <v-btn v-if="account_document.certificate_national_id" small color="primary"
              @click="goToPdf(account_document.certificate_national_id)"> عرض </v-btn>
            <span v-else>لا يوجد</span>
          </div>
          <!-- certificate_national_old -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">هوية الاحوال المدنية</span>
            <v-btn v-if="account_document.certificate_national_old" small color="primary"
              @click="goToPdf(account_document.certificate_national_old)"> عرض </v-btn>
            <span v-else>لا يوجد</span>
          </div>
          <!-- certificate_passport -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">الجواز</span>
            <v-btn v-if="account_document.certificate_passport" small color="primary"
              @click="goToPdf(account_document.certificate_passport)"> عرض </v-btn>
            <span v-else>لا يوجد</span>
          </div>
          <!-- student_certificate_nationality -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">شهادة الجنسية</span>
            <v-btn v-if="account_document.certificate_nationality" small color="primary"
              @click="goToPdf(account_document.certificate_nationality)"> عرض </v-btn>
            <span v-else>لا يوجد</span>
          </div>
          <!-- student_certificate_address -->
          <div class="py-2 px-2 d-flex justify-space-between" style="border: 1px solid cyan">
            <span class="primary--text">بطاقة السكن</span>
            <v-btn v-if="account_document.certificate_address" small color="primary"
              @click="goToPdf(account_document.certificate_address)"> عرض </v-btn>
            <span v-else>لا يوجد</span>
          </div>
        </v-col>
      </v-row>
      <v-row v-else class="mt-10 mb-2 d-flex justify-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
    </v-card>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api'

export default {
  data() {
    return {
      teacherData: {
        account_address: null,
        account_birthday: null,
        account_email: null,
        account_gender: null,
        account_id: null,
        account_mobile: null,
        account_name: null,
        account_password_show: null,
        account_payment_type: null,
        account_school: null,
        account_type: null,
        isAccountCanSendNotification: true,
        isAccountDeleted: false,
        isAccountDisabled: false,
        account_document: {
          certificate_national_id: null,
          certificate_national_old: null,
          certificate_passport: null,
          certificate_nationality: null,
          certificate_address: null,
        },
      },

      account_document: {
        certificate_national_id: null,
        certificate_national_old: null,
        certificate_passport: null,
        certificate_nationality: null,
        certificate_address: null,
      },




      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      teacherData: {},
      isPageNotLoading: true,
      featuredFingerId: 1,
      content_url: null,
    }
  },
  created() {
    const resultsLocalStorage = JSON.parse(localStorage.getItem('results'))
    this.featuredFingerId = resultsLocalStorage.features_finger_id
    this.getTeacherDataAxios()
  },

  methods: {
    async getTeacherDataAxios() {
      this.isPageNotLoading = false

      const study_year = JSON.parse(localStorage.getItem('study_year'))
      const response = await Api.getOneStudent(this.$route.params.id, study_year)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
        this.isPageNotLoading = true
      } else if (response.status === 500) {
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.isPageNotLoading = true
        this.teacherData = response.data.results

        this.account_document = response.data.results.account_document ? response.data.results.account_document : {
          certificate_national_id: null,
          certificate_national_old: null,
          certificate_passport: null,
          certificate_nationality: null,
          certificate_address: null,
        },
          this.content_url = response.data.content_url
      }
    },

    goToAbsence() {
      this.$router.push(`/showAbsence/show/details/${this.$route.params.id}/${this.$route.params.name}`)
    },
    goToNotificatoin() {
      this.$router.push(
        `/notificationForStudent/${this.teacherData.account_division_current._id}/${this.$route.params.id}/${this.$route.params.name}`,
      )
    },
    goToExams() {
      this.$router.push(
        `/examForStudent/studentId/${this.$route.params.id}/studentName/${this.$route.params.name}/classSchoolId/${this.teacherData.account_division}`,
      )
    },
    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },
    goToPdf(file) {
      window.open(this.content_url + file)
    },
  },
}
</script>

<style scoped>
.teacher_image_table {
  cursor: pointer;
}

/* @media print {
  body {
    overflow: auto;
    height: auto;
  }
  div {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
  }
  .scroll-y {
    height: auto;
    overflow: visible;
  }
} */
</style>
